<template>
  <v-btn disabled outlined plain small tile class="mx-1">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'SampleButton'
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
button.v-btn.v-btn--disabled {
  color: black !important;
}
</style>